

import React,{Component} from 'react';
import {useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import {APITransportBooking, AUTH_TOKEN } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import { clearLocalStoragePackageBookingDetails, getCodeFullName, getTodaysDate } from '../../Utilities/Functions';
import { TransportTypeList } from '../../Utilities/Data';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class TransportBooking extends Component {
constructor(props){
    
    super(props);
    this.state = {

        // Booking
        BookingTitle:'',
        CustomerName:'',
        CustomerEmail:'',
        CustomerPhone:'',
        CustomerCountry:'',
        CustomerNumberOfPeople:'',
        CustomerAccommodationRange:'',
        CustomerArrivalDate:'',
        CustomerOtherNotes:'',
        FileName:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

        // Posting
        SuccessPostMsg:'',
        ErrorPostMsg:'',
        ShowPostButton:true,
        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

    }    
}

componentDidMount(){
    this.loadBookingInfo()
}

loadBookingInfo = ()=>{
    const details = localStorage.getItem('TransportBookingDetails');
    if (details) {
        const jsonData = JSON.parse(details)
        let packageTitleName = jsonData.packageTitleName;
        let fileName = jsonData.fileUrl;
        let type = jsonData.type;
        this.setState({FileName:fileName});
        this.setState({BookingTitle:packageTitleName});
        this.setState({CustomerAccommodationRange:type});
    }
}

setCustomerNameInput = (text) =>{this.setState({CustomerName:text.target.value});}
setCustomerEmailInput = (text) =>{this.setState({CustomerEmail:text.target.value});}
setCustomerPhoneInput = (text) =>{this.setState({CustomerPhone:text.target.value});}
setCustomerCountryInput = (text) =>{this.setState({CustomerCountry:text.target.value});}
setCustomerNumberOfPeopleInput = (text) =>{this.setState({CustomerNumberOfPeople:text.target.value});}
setCustomerArrivalDateInput = (text) =>{this.setState({CustomerArrivalDate:text.target.value});}
setCustomerOtherNotesInput = (text) =>{this.setState({CustomerOtherNotes:text.target.value});}


postPackageBooking =  async () => {

    let bookingTitle = this.state.BookingTitle;
    let bookingDate = getTodaysDate()
    let customerName = this.state.CustomerName;
    let customerPhone = this.state.CustomerPhone;
    let customerEmail = this.state.CustomerEmail;
    let customerCountry = this.state.CustomerCountry;
    let customerNumberOfPeople = this.state.CustomerNumberOfPeople;
    let customerArrivalDate = this.state.CustomerArrivalDate;
    let customerOtherNotes = this.state.CustomerOtherNotes;

    if (customerName.length === 0 || customerEmail.length === 0 || customerNumberOfPeople.length === 0 
        || customerArrivalDate.length === 0 )
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('bookingTitle', bookingTitle);
        formData.append('bookingDate', bookingDate);
        formData.append('customerName', customerName);
        formData.append('customerPhone', customerPhone);
        formData.append('customerEmail', customerEmail);
        formData.append('customerCountry', customerCountry);
        formData.append('customerNumberOfPeople', customerNumberOfPeople);
        formData.append('customerArrivalDate', customerArrivalDate);
        formData.append('customerOtherNotes', customerOtherNotes);
    
        try {
                const response = await axios.post(APITransportBooking, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});                   
                clearLocalStoragePackageBookingDetails()
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}
render() {
    const { CustomerEmail, 
        CustomerName,
        CustomerPhone,
        CustomerCountry,
        CustomerNumberOfPeople,
        CustomerArrivalDate,
        CustomerOtherNotes,
    } =this.state;

return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            {this.state.ShowAxiosErrorAlert ?(<>
                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                    <p>{this.state.AllInputsRequiredError}</p>
                    <p>{this.state.AxiosErrorMessage}</p>
                    <p>{this.state.AxiosErrorResponse}</p>
                </div>
            </>):(<></>)}
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <center>
                                        <img src={this.state.FileName} alt="File Name" className="rounded-circle booking-img-left"/>
                                    </center>
                                <br></br>
                                <h6 className='black-color-text'> <b>Transport Service</b></h6>
                                <h5 className="card-title">{this.state.BookingTitle}</h5>
                                <h5 className="card-title">{getCodeFullName(this.state.CustomerAccommodationRange,TransportTypeList)}</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <center><h5 className="card-title"><b>Transport Booking Details</b></h5></center>
                                    <div className='safari-booking-grid-container-columns'>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Name | Required * </p>
                                            <input style={{width:"80%"}} className="form-control form-inputs"
                                                    value={CustomerName} placeholder='Name Required'
                                                    onChange={text => this.setCustomerNameInput (text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Email  | Required *</p>
                                            <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                    value={CustomerEmail} placeholder='Email  Required'
                                                    onChange={text => this.setCustomerEmailInput(text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Phone </p>
                                            <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                    value={CustomerPhone} placeholder='Phone'
                                                    onChange={text => this.setCustomerPhoneInput(text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Nationality | Required * </p>
                                            <input style={{width:"80%"}} className="form-control form-inputs"
                                                    value={CustomerCountry} placeholder='Nationality Required'
                                                    onChange={text => this.setCustomerCountryInput (text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Number of people | Required *</p>
                                            <input style={{width:"80%"}} className="form-control form-inputs"
                                                    value={CustomerNumberOfPeople} placeholder='Number of people Required'
                                                    onChange={text => this.setCustomerNumberOfPeopleInput (text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Arrival Date | Required *</p>
                                            <input style={{width:"80%"}} type='date' className="form-control form-inputs"
                                                    value={CustomerArrivalDate}
                                                    onChange={text => this.setCustomerArrivalDateInput(text)}
                                                />
                                        </div>
                                    </div>
                                    <br></br>
                                    <p className="primary-text">Other Notes</p>
                                    <textarea style={{width:"80%"}} className="form-control form-inputs"
                                            value={CustomerOtherNotes} placeholder='Additional Notes'
                                            onChange={text => this.setCustomerOtherNotesInput(text)}
                                            ></textarea>
                                    <br></br>
                                    <center>
                                        {this.state.ShowPostButton?(<>
                                            <button type="button" onClick={()=>this.postPackageBooking()} className="btn btn-primary">Submit Details</button>
                                        </>):(<></>)}
                                    </center>

                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <Footer/>
            </div>
        </div>
);
}}
// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(TransportBooking);


import React,{Component} from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APITransportFileName } from '../../Utilities/APIS';
import { sendEmail, getCodeFullName, displayNestedData} from '../../Utilities/Functions';
import { DestinationNamesList, TransportTypeList } from '../../Utilities/Data';

// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class TransportFilter extends Component {
constructor(props){
    
    super(props);
    this.state = {
        activeIndexBtn:"",
        SearchData:[],
        FilteredSearchData:[],

        // Loading 
        DestinationName:'',
        TransportToDate:'',
        TransportFromDate:'',
        TransportType:'',

        // modal
        ShowDetailsModel:false,

        // details 
        DetailsFileName:'',
        DetailsTitleName:'',
        DetailsDescription:'',
        DetailsLongDescription:[],

    }    
}

componentDidMount(){
    this.loadSearchDetails()
}

loadSearchDetails = ()=>{
    const details = localStorage.getItem('TransportDetails');
    const data = localStorage.getItem('TransportData');
    const transportNamesList = localStorage.getItem('TransportNamesList');

    if (details) {
        const jsonData = JSON.parse(details)
        let destinationName = jsonData.DestinationName;
        let fromDate = jsonData.FromDate;
        let toDate = jsonData.ToDate;
        let type = jsonData.Type;
        this.setState({TransportFromDate:fromDate});
        this.setState({TransportToDate:toDate});
        this.setState({DestinationName:destinationName});
        this.setState({TransportType:type});
    }
    if (data) {
        const searchData  = JSON.parse(data)
        this.setState({FilteredSearchData:searchData})

    }
    if (transportNamesList) {
        const searchData  = JSON.parse(transportNamesList)
        this.setState({SearchData:searchData})
    }
}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false});}
handleShowDetailsModel = (fileName,titleName,description,longDescription) => {
    this.setState({DetailsFileName:fileName})
    this.setState({DetailsTitleName:titleName})
    this.setState({DetailsDescription:description})
    this.setState({DetailsLongDescription:longDescription})
    this.setState({ShowDetailsModel:true});
}

goToTransportBooking = (fileName, name,country, type)=>{
    const fileUrl = APITransportFileName+fileName
    const packageTitleName = name +" | "+ getCodeFullName(country,DestinationNamesList)
    let Details = { fileUrl, packageTitleName, type};
    localStorage.setItem('TransportBookingDetails',JSON.stringify(Details));
    this.props.navigate("/transportBooking");
}

filterDataByCountry = (code) => {
    let filteredArray = this.state.SearchData.filter(item => item.country === code);
    this.setState({FilteredSearchData:filteredArray})
};

handleFilterClick = (filterName,code,country) => {
    this.setState({activeIndexBtn:filterName});
    this.setState({DestinationName:country});
    this.filterDataByCountry(code)
};

displaySearchData = () => {
    return (
        this.state.FilteredSearchData && this.state.FilteredSearchData.map((item, index) => {
            if (index % 3 === 0) {
                return (
                    <section className="section" key={index}>
                        <div className="row">
                            {[0, 1, 2].map(i => {
                                const dataItem = this.state.FilteredSearchData[index + i];
                                return dataItem ? (
                                    <div className="col-lg-4" key={index + i}>
                                        <div className="card custom-card">
                                            <div className="card-body custom-card">
                                                <img 
                                                    src={APITransportFileName+dataItem.fileName} 
                                                    className="d-block w-100 custom-card-imgs" 
                                                    alt="Hotel" 
                                                />
                                                <h5 className="card-title dashboard-card-title">
                                                    {dataItem.name}
                                                </h5>
                                                <p className="dashboard-card-description"> {getCodeFullName(dataItem.country,DestinationNamesList)}</p>
                                                <p className="dashboard-card-description"> {getCodeFullName(dataItem.type,TransportTypeList)}</p>
                                                <p className="dashboard-card-description single-line"> {dataItem.description}</p>
                                                <br />
                                                <div>
                                                    <button 
                                                        type="button" onClick={() =>this.handleShowDetailsModel(
                                                            dataItem.fileName,
                                                            dataItem.safariTitleName,
                                                            dataItem.description,
                                                            dataItem.longDescription,
    
                                                        )} 
                                                        className="btn btn-success rounded-pill" >
                                                        Read More ..
                                                    </button>
                                                    <button type="button" onClick={()=>this.goToTransportBooking(dataItem.fileName,dataItem.name,dataItem.country,dataItem.type)}
                                                        className="btn btn-primary rounded-pill tour-package-book-package-btn" >
                                                        Book Transport
                                                    </button>
                                                    
                                                    <br></br>
                                                    
                                                </div>
                                                <button type="button"className="btn btn-outline-warning email-btn"
                                                    onClick={()=>sendEmail(dataItem.name + "| "+ getCodeFullName(dataItem.country,DestinationNamesList))}
                                                >
                                                    Quick Inquiry
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </section>
                );
            } else {
                return null;
            }
        })
    );
}

render() {
    const {ShowDetailsModel} = this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <p className="text-primary">Destination : {getCodeFullName(this.state.DestinationName,DestinationNamesList)}</p>
                                    <p className="text-primary">From  : {this.state.TransportFromDate}</p>
                                    <p className="text-primary">To : {this.state.TransportToDate}</p>
                                    <p className="text-primary">Type : {this.state.TransportType}</p>

                                    <div className="list-group">
                                        {DestinationNamesList.map((item, index) => (
                                            <button  key={index} type="button"
                                            className={`list-group-item list-group-item-action ${
                                                this.state.activeIndexBtn === index ? "active" : ""}`}
                                            onClick={() => this.handleFilterClick(index,item.code,item.name)}
                                            >
                                            {item.name}
                                            </button>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="col-lg-9">
                            {this.state.FilteredSearchData.length !== 0 ?(<>{this.displaySearchData()}</>):(<>
                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                    Sorry No Records Found  For Your Search !!! 
                                </div>
                            </>)}
                        </div>
                    </div>
                </section>
                {/* Details Model   */}
                <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title><b>Transport Details</b></Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            <img 
                                src={APITransportFileName+this.state.DetailsFileName } 
                                className="d-block w-100 custom-card-imgs" 
                                alt="..." />
                            <br></br>
                            <h5 className="card-title"><b>{this.state.DetailsTitleName}</b></h5>
                            <p>{this.state.DetailsDescription}</p>
                            <p className='green-text primary-text'><b>Description</b></p>
                            {this.state.DetailsLongDescription.length !==0 && displayNestedData(this.state.DetailsLongDescription)}

                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={()=>sendEmail(this.state.DetailsTitleName)}>
                            Quick Inquiry
                        </Button>
                        <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>    
                
                <Footer/>
            </div>
        </div>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(TransportFilter);


import React,{Component} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate} from 'react-router-dom';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import logoIcon from "../../Utilities/imgs/logo.png";

class Footer extends Component {
constructor(props){
    
    super(props);
    this.state = {
        
    }    
}

componentDidMount(){
}


render() {
const { } =this.state;
return (
            <div className="row footer-container">
                
                <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card footer-cards shadow h-100 py-2">
                        <span  >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-light text-uppercase mb-1">
                                            <Navbar.Brand >
                                                <Link to='/'>
                                                    <img src={logoIcon} alt="Logo" className='logo-img' />
                                                </Link>
                                            </Navbar.Brand>
                                            <span className='nav-links-text'>Isoke Pearl Safaris <i> Unleash Your Spirit </i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card footer-cards shadow h-100 py-2">
                            <span >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-light text-uppercase mb-1">Social Media</div>
                                        <span className='nav-links-text'><i>X</i></span><br></br>
                                        <span className='nav-links-text'><i>YouTube</i></span><br></br>
                                        <span className='nav-links-text'><i>FaceBook</i></span><br></br>

                                    </div>
                                </div>
                            </div>
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4 ">
                        <div className="card footer-cards shadow h-100 py-2">
                        <span  >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-light text-uppercase mb-1">
                                        Other Links</div>
                                        <Nav className="me-auto">
                                            <Nav.Link as={Link} to="/blogs">
                                                <span className='nav-links-text'>Blogs</span>
                                            </Nav.Link>
                                        </Nav>
                                        <Nav className="me-auto">
                                            <Nav.Link as={Link} to="/aboutUs">
                                                <span className='nav-links-text'>About Us</span>
                                            </Nav.Link>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                        </span>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card footer-cards shadow h-100 py-2">
                        <span   >
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-light text-uppercase mb-1">Contact Us</div>
                                        <span className='nav-links-text'>0706 133 690</span><br></br>
                                        <a  href="https://wa.me/256706133690" className="nav-links-text">Whats App Us</a>
                                    </div>
                                </div>
                            </div>
                        </span>
                        </div>
                    </div>
            </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}


export default withNavigation(Footer);
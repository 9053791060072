

import React,{Component} from 'react';
import {  useNavigate } from 'react-router-dom';
// import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import {APIPackageFileName, } from '../../Utilities/APIS';
import { displayNestedPackageData, getCodeFullName, sendEmail } from '../../Utilities/Functions';
import { DestinationNamesList, TourDaysList, TourNamesList } from '../../Utilities/Data';
// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class PackageDetails extends Component {
constructor(props){
    
    super(props);
    this.state = {
        FileName:'',
        PackageTitleName:'',
        Country:'',
        TourName:'',
        Days:'',
        WhyBookThisTrip:[],
        ActivityHighlights:[],
        
    }    
}


componentDidMount(){
    this.loadBookingInfo()
}

loadBookingInfo = ()=>{
    const details = localStorage.getItem('PackageDetails');
    if (details) {
        const jsonData = JSON.parse(details)
        let fileName = jsonData.fileName;
        let country = jsonData.country;
        let tourName = jsonData.tourName;
        let days = jsonData.days;
        let packageTitleName = jsonData.packageTitleName;
        let whyBookThisTrip = jsonData.whyBookThisTrip;
        let activityHighlights = jsonData.activityHighlights;

        this.setState({FileName:fileName});
        this.setState({Country:country});
        this.setState({TourName:tourName});
        this.setState({Days:days});
        this.setState({PackageTitleName:packageTitleName});
        this.setState({WhyBookThisTrip:JSON.parse(whyBookThisTrip)});
        this.setState({ActivityHighlights:JSON.parse(activityHighlights)})
    }
}
goToPackageBooking = (fileName, titleName,country)=>{

    let fileUrl = APIPackageFileName+fileName
    let packageTitleName = titleName +" | "+ getCodeFullName(country,DestinationNamesList)
    let Details = { fileUrl, packageTitleName};
    localStorage.setItem('PackageBookingDetails',JSON.stringify(Details));
    this.props.navigate("/packageBooking");
}
render() {
    // const {WhyBookThisTrip} = this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
            <section className="section">
                    <div className="row">
                    <div className="col-lg-4">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <center>
                                        <img src={APIPackageFileName+this.state.FileName} alt="File Name" className="d-block w-100 custom-card-imgs"/>
                                    </center>
                                    <br></br>
                                    <h5 className="card-title dashboard-card-description">{this.state.PackageTitleName}</h5>
                                    <p className="dashboard-card-description"> {getCodeFullName(this.state.Country,DestinationNamesList)}</p>
                                    <p className="dashboard-card-description"> {getCodeFullName(this.state.TourName,TourNamesList )}</p>
                                    <p className="dashboard-card-description"> {getCodeFullName(this.state.Days,TourDaysList)}</p>
                                    <div>
                                    <button type="button" onClick={() =>sendEmail(this.state.PackageTitleName) } className="btn btn-success rounded-pill">
                                        Quick Inquiry
                                    </button>
                                    <button 
                                        type="button" 
                                        onClick={() => this.goToPackageBooking(
                                            this.state.FileName,
                                            this.state.PackageTitleName,
                                            this.state.Country,
                                        )} 
                                        className="btn btn-primary rounded-pill tour-package-book-package-btn"
                                    >
                                        Book Safari
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <center>
                                        {/* <span className="badge bg-success"><i className="bi bi-check-circle me-1"></i> Why Do I Book This Trip ?</span> */}
                                    </center>
                                    <h5 className="card-title dashboard-card-description"><b>Activity High Lights</b></h5>
                                    {this.state.ActivityHighlights.length !==0 && displayNestedPackageData (this.state.ActivityHighlights)}
                                    <h5 className="card-title dashboard-card-description"><b>Why Do I Book This Trip ?</b></h5>
                                    {this.state.WhyBookThisTrip.length !==0 && displayNestedPackageData (this.state.WhyBookThisTrip)}
                                    {this.state.ActivityHighlights.length !==0 && displayNestedPackageData (this.state.ActivityHighlights)}


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(PackageDetails);
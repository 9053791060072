

import React,{Component} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APIPackageNamesList, APIPackageReservationCreate, AUTH_TOKEN } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import TourIcon from "../../Utilities/imgs/tour.png"
import { getTodaysDate } from '../../Utilities/Functions';
import { AccommodationRangesList } from '../../Utilities/Data';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Reservation extends Component {
constructor(props){
    
    super(props);
    this.state = {

        PackageNamesList:[],

        // Package Reservation
        PackageReservationTitle:'',
        PackageCustomerName:'',
        PackageCustomerEmail:'',
        PackageCustomerPhone:'',
        PackageCustomerCountry:'',
        PackageCustomerNumberOfPeople:'',
        PackageCustomerAccommodationRange:'',
        PackageCustomerArrivalDate:'',
        PackageCustomerOtherNotes:'',

        // Posting
        SuccessPostMsg:'',
        ErrorPostMsg:'',
        ShowPostButton:true,
        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

    }    
}

componentDidMount(){
    this.loadData()
}

loadData = () =>{

    axios.get(APIPackageNamesList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        console.log(results)
        this.setState({PackageNamesList:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}



setPackageReservationTitleInput = (text) =>{this.setState({PackageReservationTitle:text.target.value});}
setPackageCustomerNameInput = (text) =>{this.setState({PackageCustomerName:text.target.value});}
setPackageCustomerEmailInput = (text) =>{this.setState({PackageCustomerEmail:text.target.value});}
setPackageCustomerPhoneInput = (text) =>{this.setState({PackageCustomerPhone:text.target.value});}
setPackageCustomerCountryInput = (text) =>{this.setState({PackageCustomerCountry:text.target.value});}
setPackageCustomerNumberOfPeopleInput = (text) =>{this.setState({PackageCustomerNumberOfPeople:text.target.value});}
setPackageCustomerAccommodationRangeInput = (text) =>{this.setState({PackageCustomerAccommodationRange:text.target.value});}
setPackageCustomerArrivalDateInput = (text) =>{this.setState({PackageCustomerArrivalDate:text.target.value});}
setPackageCustomerOtherNotesInput = (text) =>{this.setState({PackageCustomerOtherNotes:text.target.value});}


postPackageReservation =  async () => {

    let bookingTitle = this.state.PackageReservationTitle;
    let bookingDate = getTodaysDate();
    let packageCustomerName = this.state.PackageCustomerName;
    let packageCustomerPhone = this.state.PackageCustomerPhone;
    let packageCustomerEmail = this.state.PackageCustomerEmail;
    let packageCustomerCountry = this.state.PackageCustomerCountry;
    let packageCustomerNumberOfPeople = this.state.PackageCustomerNumberOfPeople;
    let packageCustomerArrivalDate = this.state.PackageCustomerArrivalDate;
    let packageCustomerOtherNotes = this.state.PackageCustomerOtherNotes;
    let customerAccommodationRange = this.state.PackageCustomerAccommodationRange



    if (packageCustomerName.length === 0 || packageCustomerEmail.length === 0 || packageCustomerNumberOfPeople.length === 0 
        || packageCustomerArrivalDate.length === 0 )
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('bookingTitle', bookingTitle);
        formData.append('bookingDate', bookingDate);
        formData.append('customerName', packageCustomerName);
        formData.append('customerPhone', packageCustomerPhone);
        formData.append('customerEmail', packageCustomerEmail);
        formData.append('customerCountry', packageCustomerCountry);
        formData.append('customerNumberOfPeople', packageCustomerNumberOfPeople);
        formData.append('customerArrivalDate', packageCustomerArrivalDate);
        formData.append('customerOtherNotes', packageCustomerOtherNotes);
        formData.append('customerAccommodationRange', customerAccommodationRange);

    
        try {
                const response = await axios.post(APIPackageReservationCreate, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});   
                this.setState({ShowSafariReservationButton:true})
                this.setState({ShowPackageReservationButton:true})                 

            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}
render() {
    const { 
        PackageNamesList,

        // Package
        PackageCustomerEmail, 
        PackageCustomerName,
        PackageCustomerPhone,
        PackageCustomerCountry,
        PackageCustomerNumberOfPeople,
        PackageCustomerArrivalDate,
        PackageCustomerOtherNotes,

    } =this.state;

return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            {this.state.ShowAxiosErrorAlert ?(<>
                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                    <p>{this.state.AllInputsRequiredError}</p>
                    <p>{this.state.AxiosErrorMessage}</p>
                    <p>{this.state.AxiosErrorResponse}</p>
                </div>
            </>):(<></>)}
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                    <div className="col-lg-4">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <center>
                                        <img src={TourIcon} alt="File Name" className=" reservation-img-left"/>
                                    </center>
                                    <br></br>
                                    <h5 className='card-title'>{this.state.PackageReservationTitle}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <center><h5 className="card-title"><b>Package Booking Details</b></h5></center>
                                    <div >
                                        <p className="primary-text">Package Name </p>
                                        <select style={{width:"80%"}} className="form-control form-inputs" 
                                            onChange={text => this.setPackageReservationTitleInput (text)}>
                                            <option > select</option>
                                            {PackageNamesList && PackageNamesList.map((item,index)=>(
                                                <option key={index} value={item} > {item}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='safari-booking-grid-container-columns'>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Name | Required * </p>
                                            <input style={{width:"80%"}} className="form-control form-inputs"
                                                    value={PackageCustomerName} placeholder='Name Required'
                                                    onChange={text => this.setPackageCustomerNameInput (text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Email  | Required *</p>
                                            <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                    value={PackageCustomerEmail} placeholder='Email  Required'
                                                    onChange={text => this.setPackageCustomerEmailInput(text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Phone </p>
                                            <input style={{width:"80%"}} type='text' className="form-control form-inputs"
                                                    value={PackageCustomerPhone} placeholder='Phone'
                                                    onChange={text => this.setPackageCustomerPhoneInput(text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Nationality | Required * </p>
                                            <input style={{width:"80%"}} className="form-control form-inputs"
                                                    value={PackageCustomerCountry} placeholder='Nationality Required'
                                                    onChange={text => this.setPackageCustomerCountryInput (text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Number of people | Required *</p>
                                            <input style={{width:"80%"}} className="form-control form-inputs"
                                                    value={PackageCustomerNumberOfPeople} placeholder='Number of people Required'
                                                    onChange={text => this.setPackageCustomerNumberOfPeopleInput (text)}
                                                />
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Accommodation Range | Required *</p>
                                            <select style={{width:"80%"}} className="form-control form-inputs" 
                                            onChange={text => this.setPackageCustomerAccommodationRangeInput (text)}>
                                                <option > select</option>
                                                {AccommodationRangesList.map((item,index)=>(
                                                    <option value={item.name} >{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text">Arrival Date | Required *</p>
                                            <input style={{width:"80%"}} type='date' className="form-control form-inputs"
                                                    value={PackageCustomerArrivalDate}
                                                    onChange={text => this.setPackageCustomerArrivalDateInput(text)}
                                                />
                                        </div>
                                    </div>
                                    <br></br>
                                    <p className="primary-text">Other Notes</p>
                                    <textarea style={{width:"80%"}} className="form-control form-inputs"
                                            value={PackageCustomerOtherNotes} placeholder='Additional Notes'
                                            onChange={text => this.setPackageCustomerOtherNotesInput(text)}
                                            ></textarea>
                                    <br></br>
                                    <center>
                                    {this.state.ShowPostButton?(<>
                                        <button type="button" onClick={()=>this.postPackageReservation ()} className="btn btn-primary">Submit My Reservation</button>
                                    </>):(<></>)}
                                    </center>

                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                
                <Footer/>
            </div>
        </div>
);
}}
// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Reservation);


import React,{Component} from 'react';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import {APIDestinationFileName, } from '../../Utilities/APIS';
import { displayNestedPackageData, getCodeFullName, sendEmail } from '../../Utilities/Functions';
import { DestinationNamesList } from '../../Utilities/Data';
// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class DestinationDetails extends Component {
constructor(props){
    
    super(props);
    this.state = {
        FileName:'',
        ParkName:'',
        Country:'',
        Details:[],
        
    }    
}


componentDidMount(){
    this.loadDestinationData()
}

loadDestinationData = ()=>{
    const data = localStorage.getItem('DestinationDetails');
    if (data) {
        const jsonData = JSON.parse(data)
        let fileName = jsonData.fileName;
        let country = jsonData.country;
        let parkName = jsonData.parkName;
        let details = jsonData.longDescription;

        this.setState({FileName:fileName});
        this.setState({Country:country});
        this.setState({ParkName:parkName});
        this.setState({Details:JSON.parse(details)});
    }
}
goToPackageBooking = (fileName, titleName,country)=>{

    let fileUrl = APIDestinationFileName+fileName
    let packageTitleName = titleName +" | "+ getCodeFullName(country,DestinationNamesList)
    let Details = { fileUrl, packageTitleName};
    localStorage.setItem('PackageBookingDetails',JSON.stringify(Details));
    this.props.navigate("/packageBooking");
}
render() {
    const {Details} = this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
            <section className="section">
                    <div className="row">
                    <div class="col-lg-4">
                            <div class="card custom-card">
                                <div class="card-body">
                                    <center>
                                        <img src={this.state.FileName} alt="File Name" class="d-block w-100 custom-card-imgs"/>
                                    </center>
                                    <br></br>
                                    <h5 className="card-title dashboard-card-description">{this.state.ParkName}</h5>
                                    <p className="dashboard-card-description">{getCodeFullName(this.state.Country,DestinationNamesList)}</p>
                                    <div>
                                    <button type="button" onClick={() =>sendEmail(this.state.ParkName+"|"+getCodeFullName(this.state.Country,DestinationNamesList))} className="btn btn-success rounded-pill">
                                        Quick Inquiry
                                    </button>
                                    <button 
                                        type="button" 
                                        onClick={() => this.goToPackageBooking(
                                            this.state.FileName,
                                            this.state.ParkName,
                                            this.state.Country,
                                        )} 
                                        className="btn btn-primary rounded-pill tour-package-book-package-btn"
                                    >
                                        Book Safari
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <h5 className="card-title dashboard-card-description"><b>Destination Details</b></h5>
                                    {Details.length !==0 && displayNestedPackageData(Details)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(DestinationDetails);
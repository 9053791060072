

import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';


import { APISafariFileName, APISafariList, AUTH_TOKEN } from '../../Utilities/APIS';
import {  displayNestedData, sendEmail } from '../../Utilities/Functions';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class SafariViewMore extends Component {
constructor(props){
    
    super(props);
    this.state = {
        SafarisData:[],

        // modal
        ShowDetailsModel:false,

        // details 
        DetailsFileName:'',
        DetailsDescription:'',
        DetailsLongDescription:[],
        DetailsTitleName:'',
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APISafariList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SafarisData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false});}
handleShowDetailsModel = (fileName,titleName,description,longDescription) => {
    this.setState({DetailsFileName:fileName})
    this.setState({DetailsTitleName:titleName})
    this.setState({DetailsDescription:description})
    this.setState({DetailsLongDescription:longDescription})
    this.setState({ShowDetailsModel:true});
}

displaySafariData = () => {
    const slicedSafarisData = this.state.SafarisData; //this.state.SafarisData.slice(0, 3);
    return (
            slicedSafarisData && slicedSafarisData.map((item, index) => {
            if (index % 3 === 0) {
                return (
                    <section className="section" key={index}>
                        <div className="row">
                            {[0, 1, 2].map(i => {
                                const dataItem = slicedSafarisData[index + i];
                                return dataItem ? (
                                    <div className="col-lg-4" key={index + i}>
                                        <div className="card custom-card">
                                            <div className="card-body custom-card">
                                                <img 
                                                    src={APISafariFileName + dataItem.fileName} 
                                                    className="d-block w-100 custom-card-imgs" 
                                                    alt="..." 
                                                />
                                                <h5 className="card-title dashboard-card-title">
                                                    {dataItem.safariTitleName}
                                                </h5>
                                                    <p className="dashboard-card-description single-line">One Paragraph   This is a long paragraph that will be truncated if it exceeds one line</p>
                                                <br />
                                                <div>
                                                    <button type="button" onClick={() => sendEmail(dataItem.safariTitleName)} className="btn btn-success rounded-pill">
                                                        Quick Inquiry
                                                    </button>
                                                    <button type="button" onClick={() =>this.handleShowDetailsModel(
                                                        dataItem.fileName,
                                                        dataItem.safariTitleName,
                                                        dataItem.description,
                                                        dataItem.longDescription,

                                                    )} 
                                                        className="btn btn-primary rounded-pill tour-package-book-package-btn" >
                                                        More Details
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </section>
                );
            } else {
                return null;
            }
        })
    );
}


render() {
const { ShowDetailsModel} =this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                {this.displaySafariData()}      
                    {/* Details Model   */}
                    <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <Modal.Title><b>Safari Tour Details</b></Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                            <img 
                                src={APISafariFileName+this.state.DetailsFileName } 
                                className="d-block w-100 custom-card-imgs" 
                                alt="..." />
                            <br></br>
                            <h5 className="card-title"><b>{this.state.DetailsTitleName}</b></h5>
                            <p> {this.state.DetailsDescription}</p>
                            <p className='green-text primary-text'><b>Details </b></p>
                            {this.state.DetailsLongDescription.length !==0 && displayNestedData (this.state.DetailsLongDescription)}

                            </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={()=>sendEmail(this.state.DetailsTitleName)}>
                                Quick Inquiry
                            </Button>
                            <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>            
                <Footer/>
            </div>
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(SafariViewMore);


import React,{Component} from 'react';
import {useNavigate } from 'react-router-dom';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APIPackageFileName } from '../../Utilities/APIS';
import {  getCodeFullName, sendEmail} from '../../Utilities/Functions';
import { DestinationNamesList, TourDaysList, TourNamesList } from '../../Utilities/Data';

// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class ToursFilter extends Component {
constructor(props){
    
    super(props);
    this.state = {
        activeIndexBtn:"",
        SearchData:[],
        FilteredSearchData:[],

        // Loading 
        DestinationName:'',
        TourName:'',
        PeriodName:'',
        

    }    
}

componentDidMount(){
    this.loadSearchDetails()
    // this.filterDataByCountry()
}

loadSearchDetails = ()=>{
    const details = localStorage.getItem('SearchDetails');
    const data = localStorage.getItem('SearchData');
    if (details) {
        const jsonData = JSON.parse(details)
        let destinationName = jsonData.DestinationName;
        let tourName = jsonData.TourName;
        let periodName = jsonData.PeriodName;
        this.setState({TourName:tourName});
        this.setState({DestinationName:destinationName});
        this.setState({PeriodName:periodName});
    }
    if (data) {
        const searchData  = JSON.parse(data)
        this.setState({SearchData:searchData})
        this.setState({FilteredSearchData:searchData})

    }
}
filterDataByCountry = (code) => {
    let filteredArray = this.state.SearchData.filter(item => item.tourName === code);
    this.setState({FilteredSearchData:filteredArray})
};

handleFilterClick = (filterName,code,tourName) => {
    this.setState({activeIndexBtn:filterName});
    this.setState({TourName:tourName});
    this.filterDataByCountry(code)
};
goToPackageBooking = (fileName, titleName,country)=>{

    let fileUrl = APIPackageFileName+fileName
    let packageTitleName = titleName +" | "+ getCodeFullName(country,DestinationNamesList)
    let Details = { fileUrl, packageTitleName};
    localStorage.setItem('PackageBookingDetails',JSON.stringify(Details));
    this.props.navigate("/packageBooking");
}
goToPackageDetails = (fileName, packageTitleName,country,tourName,days,whyBookThisTripData,activityHighlightsData)=>{

    let whyBookThisTrip = JSON.stringify(whyBookThisTripData); 
    let activityHighlights =JSON.stringify(activityHighlightsData);
    let Details = {fileName, packageTitleName,country,tourName,days,whyBookThisTrip,activityHighlights};
    localStorage.setItem('PackageDetails',JSON.stringify(Details));
    this.props.navigate("/packageDetail");
}
displaySearchData = () => {
    return (
        this.state.FilteredSearchData && this.state.FilteredSearchData.map((item, index) => {
            if (index % 3 === 0) {
                return (
                    <section className="section" key={index}>
                        <div className="row">
                            {[0, 1, 2].map(i => {
                                const dataItem = this.state.FilteredSearchData[index + i];
                                return dataItem ? (
                                    <div className="col-lg-4" key={index + i}>
                                        <div className="card custom-card">
                                            <div className="card-body custom-card">
                                                <img 
                                                    src={APIPackageFileName + dataItem.fileName} 
                                                    className="d-block w-100 custom-card-imgs" 
                                                    alt="..." 
                                                />
                                                <h5 className="card-title dashboard-card-title">
                                                    {dataItem.packageTitleName}
                                                </h5>
                                                {/* {displayNestedData(dataItem.shortDescription)} */}
                                                <div className="alert border-dark alert-dismissible fade show" role="alert">
                                                    <p className="dashboard-card-description"> {getCodeFullName(dataItem.country,DestinationNamesList)}</p>
                                                    <p className="dashboard-card-description"> {getCodeFullName(dataItem.tourName,TourNamesList)}</p>
                                                    <p className="dashboard-card-description"> {getCodeFullName(dataItem.days,TourDaysList)}</p>
                                                </div>
                                                <br />
                                                <div>
                                                    <button 
                                                        type="button" 
                                                        onClick={() => this.goToPackageDetails(
                                                            dataItem.fileName, 
                                                            dataItem.packageTitleName, 
                                                            dataItem.country,
                                                            dataItem.tourName,
                                                            dataItem.days,
                                                            dataItem.whyBookThisTrip, 
                                                            dataItem.activityHighlights,
                                                        )} 
                                                        className="btn btn-success rounded-pill"
                                                    >
                                                        Read More ..
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        onClick={() => this.goToPackageBooking(
                                                            dataItem.fileName, 
                                                            dataItem.packageTitleName, 
                                                            dataItem.country,
                                                        )} 
                                                        className="btn btn-primary rounded-pill tour-package-book-package-btn"
                                                    >
                                                        Book Safari
                                                    </button><br></br>
                                                    
                                                </div>
                                                <button 
                                                    type="button" 
                                                    onClick={() =>sendEmail(dataItem.packageTitleName+ " | "+getCodeFullName(dataItem.country,DestinationNamesList))} 
                                                    className="btn btn-outline-warning email-btn"
                                                >
                                                    Quick Inquiry
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </section>
                );
            } else {
                return null;
            }
        })
    );
}

render() {
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <p className="text-primary">Destination : {this.state.DestinationName}</p>
                                    <p className="text-primary">Tour Name : {this.state.TourName}</p>
                                    <p className="text-primary">Period : {this.state.PeriodName}</p>

                                    <div className="list-group">
                                        {TourNamesList.map((item, index) => (
                                            <button  key={index} type="button"
                                            className={`list-group-item list-group-item-action ${
                                                this.state.activeIndexBtn === index ? "active" : ""}`}
                                            onClick={() => this.handleFilterClick(index,item.code,item.name)} // Set the active index
                                            >
                                            {item.name}
                                            </button>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="col-lg-9">
                            {this.state.FilteredSearchData.length !== 0 ?(<>{this.displaySearchData()}</>):(<>
                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                    Sorry No Records Found  For Your Search !!! 
                                </div>
                            </>)}
                        </div>
                    </div>
                </section>
                
                <Footer/>
            </div>
        </div>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(ToursFilter);
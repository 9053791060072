
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

// Safaris
import Safaris from './Components/Pages/Safaris/Safaris';
import SafariViewMore from './Components/Pages/Safaris/SafariViewMore';


// packages
import TourPackages from './Components/Pages/Blogs/Blogs';
import PackageDetail from './Components/Pages/Packages/PackageDetail';

// Contact us
import ContactUs from './Components/Pages/ContactUs/ContactUs';

// About us
import AboutUs from './Components/Pages/AboutUs/AboutUs';

// Quotes
import Reservation from './Components/Pages/Reservation/Reservation';
import ToursFilter from './Components/Pages/SearchFilter/ToursFilter';
import HotelsFilter from './Components/Pages/SearchFilter/HotelsFilter';
import TransportFilter from './Components/Pages/SearchFilter/TransportFilter';

// Booking 
import PackageBooking from './Components/Pages/Booking/PackageBooking';
import TransportBooking from './Components/Pages/Booking/TransportBooking';

// Blogs
import Blogs from './Components/Pages/Blogs/Blogs';
import Destination from './Components/Pages/Destination/Destination';
import DestinationDetails from './Components/Pages/Destination/DestinationDetails';
import SafariTours from './Components/Pages/SafariTours/SafariTours';
import Transport from './Components/Pages/Transport/Transport';

function App() {

	return (
        <Router >
			<Routes>
				<Route path="/" element={<Safaris/>} />
				<Route path="/safariViewMore" element={<SafariViewMore/>} />
				<Route path="/safariTours" element={<SafariTours/>} />


				<Route path="/packages" element={<TourPackages/>} />
				<Route path="/packageDetail" element={<PackageDetail/>} />

				<Route path="/packageBooking" element={<PackageBooking/>} />
				<Route path="/transportBooking" element={<TransportBooking/>} />
				
				<Route path="/contactUs" element={<ContactUs/>} />
				<Route path="/aboutUs" element={<AboutUs/>} />
				<Route path="/blogs" element={<Blogs/>} />
				<Route path="/reservation" element={<Reservation/>} />
				<Route path="/destination" element={<Destination/>} />
				<Route path="/destinationDetail" element={<DestinationDetails/>} />
				<Route path="/tourFilter" element={<ToursFilter/>} />
				<Route path="/hotelsFilter" element={<HotelsFilter/>} />

				<Route path="/transport" element={<Transport/>} />
				<Route path="/transportFilter" element={<TransportFilter/>} />



			</Routes>
        </Router>
		
);}

export default App;

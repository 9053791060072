

import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';


import { APISafariFileName, APISafariList, APISliderFileName, APISliderList, AUTH_TOKEN } from '../../Utilities/APIS';
import { displayNestedData, sendEmail } from '../../Utilities/Functions';
import SearchFilter from '../SearchFilter/SearchFilter';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Safaris extends Component {
constructor(props){
    
    super(props);
    this.state = {
        index:0,
        SafarisData:[],
        SliderData:[],

        // modal
        ShowDetailsModel:false,

        // details 
        DetailsFileName:'',
        DetailsDescription:'',
        DetailsLongDescription:[],
        DetailsTitleName:'',
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APISafariList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SafarisData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })

    axios.get(APISliderList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SliderData:jsonResults})

        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}

handleSelect = (selectedIndex) => {
    this.setState({index:selectedIndex});
}


handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false});}
handleShowDetailsModel = (fileName,titleName,description,longDescription) => {
    this.setState({DetailsFileName:fileName})
    this.setState({DetailsTitleName:titleName})
    this.setState({DetailsDescription:description})
    this.setState({DetailsLongDescription:longDescription})
    this.setState({ShowDetailsModel:true});
}

goToSafariViewMore = ()=>{
    this.props.navigate("/safariViewMore")
}

 // Function to get 3 random items
getRandomSafaris = (data, count) => {
    const shuffled = [...data].sort(() => 0.5 - Math.random()); // Shuffle the array
    return shuffled.slice(0, count); // Get the first 'count' items
};


displaySafariData = () => {
    // const slicedSafarisData = this.state.SafarisData.slice(0, 3);
    const randomSafaris = this.getRandomSafaris(this.state.SafarisData, 6);
    return (
        randomSafaris && randomSafaris.map((item, index) => {
            if (index % 3 === 0) {
                return (
                    <section className="section" key={index}>
                        <div className="row">
                            {[0, 1, 2].map(i => {
                                const dataItem = randomSafaris[index + i];
                                return dataItem ? (
                                    <div className="col-lg-4" key={index + i}>
                                        <div className="card custom-card">
                                            <div className="card-body custom-card">
                                                <img 
                                                    src={APISafariFileName + dataItem.fileName} 
                                                    className="d-block w-100 custom-card-imgs" 
                                                    alt="..." 
                                                />
                                                <h5 className="card-title dashboard-card-title">
                                                    {dataItem.safariTitleName}
                                                </h5>
                                                <p className="dashboard-card-description single-line">{dataItem.description}</p>
                                                <br />
                                                <div>
                                                    <button type="button" onClick={() => sendEmail(dataItem.safariTitleName)} className="btn btn-success rounded-pill">
                                                        Quick Inquiry
                                                    </button>
                                                    <button type="button" onClick={() =>this.handleShowDetailsModel(
                                                        dataItem.fileName,
                                                        dataItem.safariTitleName,
                                                        dataItem.description,
                                                        dataItem.longDescription,

                                                    )} 
                                                        className="btn btn-primary rounded-pill tour-package-book-package-btn" >
                                                        More Details
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </section>
                );
            } else {
                return null;
            }
        })
    );
}


render() {
const { ShowDetailsModel,SliderData} =this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div>
                <Carousel activeIndex={this.state.index} onSelect={()=>this.handleSelect()}>
                    {SliderData && SliderData.map((item, index) => (
                        <Carousel.Item key={index} interval={3000}>
                            <img 
                                src={`${APISliderFileName}${item.fileName}`} 
                                className="d-block w-100 carousel-img" 
                                alt={`Slide ${index + 1}`} 
                            />
                            <Carousel.Caption>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className='inner-body-container'>
                {SliderData && SliderData.length !== 0 ?(<><SearchFilter/></>):(<></>)}
                <div className="alert border-success alert-dismissible fade show" role="alert">
                    <div>
                        <h2 className="card-title"><b>Our Safari Tours</b></h2>
                    </div>
                    <div className='safari-right-float'>
                        <button className='btn btn-success rounded-pill' 
                            onClick={()=>this.goToSafariViewMore()}
                        >-- View More --</button>
                    </div>
                </div>
                {this.displaySafariData()} 
                {/* Details Model   */}
                <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title><b>Safari Tour Details</b></Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            <img 
                                src={APISafariFileName+this.state.DetailsFileName } 
                                className="d-block w-100 custom-card-imgs" 
                                alt="..." />
                            <br></br>
                            <h5 className="card-title"><b>{this.state.DetailsTitleName}</b></h5>
                            <p> {this.state.DetailsDescription}</p>
                            <p className='green-text primary-text'><b>Details </b></p>
                            {this.state.DetailsLongDescription.length !==0 && displayNestedData(this.state.DetailsLongDescription)}

                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={()=>sendEmail(this.state.DetailsTitleName)}>
                            Quick Inquiry
                        </Button>
                        <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>               
                <Footer/>
            </div>
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(Safaris);
import { APP_AUTH_TOKEN, APP_URL } from "./Env";

const BaseUrl = APP_URL; // Set App url
export const AUTH_TOKEN = APP_AUTH_TOKEN;


// Slider 
export const APISliderList = BaseUrl+"/api/isoke/slider/list/all";
export const APISliderFileName = BaseUrl+"/api/isoke/slider/image/file/";


// Safaris 
export const APISafariList = BaseUrl+"/api/isoke/safari/list/all";
export const APISafariFileName = BaseUrl+"/api/isoke/safari/image/file/";

// Package 
export const APIPackageBooking = BaseUrl+"/api/isoke/package/booking/create";
export const APIPackageList = BaseUrl+"/api/isoke/package/list/all";
export const APIPackageFileName = BaseUrl+"/api/isoke/package/image/file/";
export const APIPackageReservationCreate = BaseUrl+"/api/isoke/package/reservation/create";
export const APIPackageNamesList = BaseUrl+"/api/isoke/package/list/names";

// Hotel
export const APIHotelList = BaseUrl+"/api/isoke/hotel/list/all";
export const APIHotelFileName = BaseUrl+"/api/isoke/hotel/image/file/";
export const APIHotelNamesList = BaseUrl+"/api/isoke/hotel/list/names";

// Blogs
export const APIBlogList = BaseUrl+"/api/isoke/blog/list/all";
export const APIBlogFileName = BaseUrl+"/api/isoke/blog/image/file/";

// Destination
export const APIDestinationList = BaseUrl+"/api/isoke/destination/list/all";
export const APIDestinationFileName = BaseUrl+"/api/isoke/destination/image/file/";

// Transport
export const APITransportList = BaseUrl+"/api/isoke/transport/list/all";
export const APITransportFileName = BaseUrl+"/api/isoke/transport/image/file/";
export const APITransportBooking = BaseUrl+"/api/isoke/transport/booking/create";

// Contact Us
export const APIContactUsCreate = BaseUrl+"/api/isoke/contact/us/create";
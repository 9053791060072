

import React,{Component} from 'react';
import {useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APIHotelFileName, APISafariFileName } from '../../Utilities/APIS';
import {  sendEmail} from '../../Utilities/Functions';


import {  DestinationNamesList } from '../../Utilities/Data';

// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class HotelsFilter extends Component {
constructor(props){
    
    super(props);
    this.state = {
        activeIndexBtn:"",
        SearchData:[],
        FilteredSearchData:[],

        // Loading 
        DestinationName:'',
        HotelsCustomerCheckInDate:'',
        HotelsCustomerCheckOutDate:'',
        HotelsCustomerNumberOfPeople:'',

        // modal
        ShowDetailsModel:false,

        // details 
        DetailsFileName:'',
        DetailsTitleName:'',

    }    
}

componentDidMount(){
    this.loadSearchDetails()
}

loadSearchDetails = ()=>{
    const details = localStorage.getItem('HotelDetails');
    const data = localStorage.getItem('HotelsData');
    const hotelDataList = localStorage.getItem('HotelDataList');
    if (details) {
        const jsonData = JSON.parse(details)
        let destinationName = jsonData.DestinationName;
        let checkIn = jsonData.CheckIn;
        let checkOut = jsonData.CheckOut;
        let people = jsonData.People;
        this.setState({HotelsCustomerCheckOutDate:checkOut});
        this.setState({HotelsCustomerCheckInDate:checkIn});
        this.setState({DestinationName:destinationName});
        this.setState({HotelsCustomerNumberOfPeople:people});
    }
    if (data) {
        const searchData  = JSON.parse(data)
        this.setState({FilteredSearchData:searchData})
    }
    if (hotelDataList) {
        const searchData  = JSON.parse(hotelDataList)
        this.setState({SearchData:searchData})
    }
}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false});}
handleShowDetailsModel = (fileName,titleName) => {
    this.setState({DetailsFileName:fileName})
    this.setState({DetailsTitleName:titleName})
    this.setState({ShowDetailsModel:true});
}

goToHotelBooking = (fileName, name,country)=>{
    const fileUrl = APISafariFileName+fileName
    const packageTitleName = name +" | "+ country
    let Details = { fileUrl, packageTitleName};
    localStorage.setItem('PackageBookingDetails',JSON.stringify(Details));
    this.props.navigate("/packageBooking");
}

filterDataByCountry = (code) => {
    let filteredArray = this.state.SearchData.filter(item => item.country === code);
    this.setState({FilteredSearchData:filteredArray})
};

handleFilterClick = (filterName,country,code) => {
    this.setState({activeIndexBtn:filterName});
    this.setState({DestinationName:country});
    this.filterDataByCountry(code)


};

displaySearchData = () => {
    return (
        this.state.FilteredSearchData && this.state.FilteredSearchData.map((item, index) => {
            if (index % 3 === 0) {
                return (
                    <section className="section" key={index}>
                        <div className="row">
                            {[0, 1, 2].map(i => {
                                const dataItem = this.state.FilteredSearchData[index + i];
                                return dataItem ? (
                                    <div className="col-lg-4" key={index + i}>
                                        <div className="card custom-card">
                                            <div className="card-body custom-card">
                                                <img 
                                                    src={APIHotelFileName+dataItem.fileName} 
                                                    className="d-block w-100 custom-card-imgs" 
                                                    alt="Hotel" 
                                                />
                                                <h5 className="card-title dashboard-card-title">
                                                    {dataItem.safariTitleName}
                                                </h5>
                                                <p className="dashboard-card-description"> {dataItem.name}</p>
                                                <p className="dashboard-card-description"> {dataItem.country}</p>
                                                <p className="dashboard-card-description single-line"> {dataItem.description}</p>
                                                <br />
                                                <div>
                                                    <button 
                                                        type="button" onClick={() =>this.handleShowDetailsModel(
                                                            dataItem.fileName,
                                                            dataItem.safariTitleName,
    
                                                        )} 
                                                        className="btn btn-success rounded-pill" >
                                                        Read More ..
                                                    </button>
                                                    <button type="button" onClick={()=>this.goToHotelBooking(dataItem.fileName,dataItem.name,dataItem.country)}
                                                        className="btn btn-primary rounded-pill tour-package-book-package-btn" >
                                                        Book Hotel
                                                    </button>
                                                    
                                                    <br></br>
                                                    
                                                </div>
                                                <button type="button"className="btn btn-outline-warning email-btn"
                                                    onClick={()=>sendEmail(dataItem.name)}
                                                >
                                                    Quick Inquiry
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </section>
                );
            } else {
                return null;
            }
        })
    );
}

render() {
    const {ShowDetailsModel} = this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <p className="text-primary">Destination : {this.state.DestinationName}</p>
                                    <p className="text-primary">Check In : {this.state.HotelsCustomerCheckInDate}</p>
                                    <p className="text-primary">Check Out : {this.state.HotelsCustomerCheckOutDate}</p>
                                    <p className="text-primary">Class Range : {this.state.HotelsCustomerNumberOfPeople}</p>

                                    <div className="list-group">
                                        {DestinationNamesList.map((item, index) => (
                                            <button  key={index} type="button"
                                            className={`list-group-item list-group-item-action ${
                                                this.state.activeIndexBtn === index ? "active" : ""}`}
                                            onClick={() => this.handleFilterClick(index,item.name,item.code)}
                                            >
                                            {item.name}
                                            </button>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="col-lg-9">
                            {this.state.FilteredSearchData.length !== 0 ?(<>{this.displaySearchData()}</>):(<>
                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                    Sorry No Records Found  For Your Search !!! 
                                </div>
                            </>)}
                        </div>
                    </div>
                </section>
                {/* Details Model   */}
                <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title><b>Safari Tour Details</b></Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            <img 
                                src={APISafariFileName+this.state.DetailsFileName } 
                                className="d-block w-100 custom-card-imgs" 
                                alt="..." />
                            <br></br>
                            <h5 className="card-title"><b>{this.state.DetailsTitleName}</b></h5>
                            <p> On Paragraph</p>
                            <p className='green-text primary-text'><b>Description</b></p>
                            <p> description 1</p>
                            <p> description 2</p>
                            <p> description 3</p>

                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={()=>sendEmail(this.state.DetailsTitleName)}>
                            Quick Inquiry
                        </Button>
                        <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>    
                
                <Footer/>
            </div>
        </div>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(HotelsFilter);


import React,{Component} from 'react';
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import Footer from '../Navigation/Footer';

import { APIDestinationFileName, APIDestinationList, APIPackageFileName, AUTH_TOKEN } from '../../Utilities/APIS';
import {  getCodeFullName, sendEmail} from '../../Utilities/Functions';
import { DestinationNamesList } from '../../Utilities/Data';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Destination extends Component {
constructor(props){
    
    super(props);
    this.state = {
        activeIndexBtn:"",
        SearchData:[],
        FilteredSearchData:[],

        // Loading 
        DestinationName:'UG',
        country:'',
        PeriodName:'',
        

    }    
}

componentDidMount(){
    this.loadSearchData()
}

loadSearchData = ()=>{
    axios.get(APIDestinationList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SearchData:jsonResults})
        this.setState({FilteredSearchData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
filterDataByCountry = (country) => {
    let code = String(country)
    let filteredArray = this.state.SearchData.filter(item => item.country === code);
    this.setState({FilteredSearchData:filteredArray})
};

handleFilterClick = (filterName,country) => {
    this.setState({activeIndexBtn:filterName});
    this.setState({DestinationName:country});
    this.filterDataByCountry(country)
};
goToPackageBooking = (fileName, parkName,country)=>{

    const fileUrl = APIDestinationFileName+fileName
    const packageTitleName = parkName +" | "+ country;
    let Details = { fileUrl, packageTitleName};
    localStorage.setItem('PackageBookingDetails',JSON.stringify(Details));
    this.props.navigate("/packageBooking");
}
goToDestinationDetails = (file, parkName,country,details)=>{
    let longDescription = JSON.stringify(details)
    let fileName = APIDestinationFileName+file;
    let Details = {fileName, parkName,country,longDescription};
    localStorage.setItem('DestinationDetails',JSON.stringify(Details));
    this.props.navigate("/destinationDetail");
}
displaySearchData = () => {
    return (
        this.state.FilteredSearchData && this.state.FilteredSearchData.map((item, index) => {
            if (index % 3 === 0) {
                return (
                    <section className="section" key={index}>
                        <div className="row">
                            {[0, 1, 2].map(i => {
                                const dataItem = this.state.FilteredSearchData[index + i];
                                return dataItem ? (
                                    <div className="col-lg-4" key={index + i}>
                                        <div className="card custom-card">
                                            <div className="card-body custom-card">
                                                <img 
                                                    src={APIDestinationFileName+dataItem.fileName} 
                                                    className="d-block w-100 custom-card-imgs" 
                                                    alt="..." 
                                                />
                                                <p></p>
                                                <div className="alert border-success alert-dismissible fade show" role="alert">
                                                    <h5 className="card-title dashboard-card-title">
                                                        {dataItem.name}
                                                    </h5>
                                                    <p className="dashboard-card-description"> {getCodeFullName(dataItem.country,DestinationNamesList)}</p>
                                                </div>
                                                <br />
                                                <div>
                                                    <button 
                                                        type="button" 
                                                        onClick={() => this.goToDestinationDetails(
                                                            dataItem.fileName, 
                                                            dataItem.name, 
                                                            dataItem.country,
                                                            dataItem.longDescription, 
                                                        )} 
                                                        className="btn btn-success rounded-pill"
                                                    >
                                                        Read More ..
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        onClick={() => this.goToPackageBooking(
                                                            dataItem.fileName, 
                                                            dataItem.name, 
                                                            dataItem.country,
                                                        )} 
                                                        className="btn btn-primary rounded-pill tour-package-book-package-btn"
                                                    >
                                                        Book Destination
                                                    </button><br></br>
                                                    
                                                </div>
                                                <button 
                                                        type="button" 
                                                        onClick={() =>sendEmail(dataItem.name+"|"+this.state.DestinationName)} 
                                                        className="btn btn-outline-warning email-btn"
                                                    >
                                                        Quick Inquiry
                                                    </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </section>
                );
            } else {
                return null;
            }
        })
    );
}

render() {
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card custom-card-no-color">
                                <div className="card-body">
                                    <p className="text-primary">Destination : {getCodeFullName(this.state.DestinationName,DestinationNamesList)}</p>

                                    <div className="list-group">
                                        {DestinationNamesList.map((item, index) => (
                                            <button  key={index} type="button"
                                            className={`list-group-item list-group-item-action ${
                                                this.state.activeIndexBtn === index ? "active" : ""}`}
                                            onClick={() => this.handleFilterClick(index,item.code)} // Set the active index
                                            >
                                            {item.name}
                                            </button>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="col-lg-9">
                            {this.state.FilteredSearchData.length !== 0 ?(<>{this.displaySearchData()}</>):(<>
                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                    Sorry No Records Found  For Your Search !!! 
                                </div>
                            </>)}
                        </div>
                    </div>
                </section>
                
                <Footer/>
            </div>
        </div>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(Destination);
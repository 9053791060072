import React,{useState} from 'react';
import {Link} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import whatsAppIcon from "../../Utilities/imgs/whatsApp.png";
import logoIcon from "../../Utilities/imgs/logo.png";
import { DestinationNamesList, TourNamesList, TransportTypeList } from '../../Utilities/Data';

function HorizontalHeader () {
    const [showTours, setShowTours] = useState(false);
    const [showDestination, setShowDestination] = useState(false);
    const [showTransport, setShowTransport] = useState(false);


    const handleToursMouseEnter = () => setShowTours(true);
    const handleToursMouseLeave = () => setShowTours(false);
    const handleDestinationMouseEnter = () => setShowDestination(true);
    const handleDestinationMouseLeave = () => setShowDestination(false);
    const handleTransportMouseEnter = () => setShowTransport(true);
    const handleTransportMouseLeave = () => setShowTransport(false);
return (
        <div  >
            <Navbar className='nav-header-container' data-bs-theme="light" >
                <Container >
                <Navbar.Brand >
                    <Link to='/'>
                        <img src={logoIcon} alt="Logo" className='logo-img' />
                    </Link>
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link as={Link} to="/">
                        <span className='nav-links-text'>Home</span>
                    </Nav.Link>
                    <NavDropdown  title="Safari Tours" id="tours-nav-dropdown"
                        onMouseEnter={handleToursMouseEnter}
                        onMouseLeave={handleToursMouseLeave}
                        show={showTours}>
                        <NavDropdown.Divider />
                            {TourNamesList.map((item,index)=>(
                                <div key={index}>
                                    <NavDropdown.Item className='search-filter-labels'>
                                        <Link to={item.path}>
                                            {item.name}
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </div>
                            )) }
                        </NavDropdown>
                    <NavDropdown  title="Destination" id="destination-nav-dropdown"
                        onMouseEnter={handleDestinationMouseEnter}
                        onMouseLeave={handleDestinationMouseLeave}
                        show={showDestination}
                    >
                        <NavDropdown.Divider />
                        {DestinationNamesList.map((item,index)=>(
                                <div key={index}>
                                    <NavDropdown.Item className='search-filter-labels'>
                                        <Link to={item.path}>
                                            {item.name}
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </div>
                            )) }
                    </NavDropdown>
                    <NavDropdown  title="Transport" id="transport-nav-dropdown"
                        onMouseEnter={handleTransportMouseEnter}
                        onMouseLeave={handleTransportMouseLeave}
                        show={showTransport}
                    >
                        <NavDropdown.Divider />
                        {TransportTypeList.map((item,index)=>(
                                <div key={index}>
                                    <NavDropdown.Item className='search-filter-labels'>
                                        <Link to={item.path}>
                                            {item.name}
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </div>
                            )) }
                    </NavDropdown>
                    <Nav.Link as={Link} to="/contactUs">
                        <span className='nav-links-text'>Contact Us</span>
                    </Nav.Link>
                </Nav>
                </Container>
                <div>
                    <button type="button" className="btn btn-light rounded-pill call-now-btn "><i className="bi bi-whatsapp me-1"></i> Call : 0706133690 </button>
                </div>
                <div >
                    <Link to="/reservation" >
                        <button type="button" className="btn btn-primary rounded-pill book-now-btn">Make Reservation </button>
                    </Link>
                </div>
            </Navbar>
            
            <div>
                <a  href="https://wa.me/256706133690" className="whatsapp_float">
                    <img src={whatsAppIcon} alt="WhatsApp" />
                </a><br/><br/>
            </div>
        </div>
);
}

export default HorizontalHeader;


import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';


import { APIHotelList, APIPackageList, APITransportList, AUTH_TOKEN } from '../../Utilities/APIS';
import { AccommodationRangesList, DestinationNamesList, TourDaysList, TourNamesList, TransportTypeList } from '../../Utilities/Data';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class SearchFilter extends Component {
constructor(props){
    
    super(props);
    this.state = {
        activeFilter:"",
        HotelDataList:[],
        PackageTourDataList:[],
        TransportNamesList:[],


        ToursDestinationCode:'',
        ToursDestinationName:'',
        ToursTourName:'',
        ToursPeriodName:'',


        // Errors
        ShowRequiredError:'',

        // Screens 
        ShowToursSearchScreen:true,
        ShowHotelsSearchScreen:false,
        ShowTransportsSearchScreen:false,

        // Hotels
        HotelsDestinationName:'',
        HotelsCustomerCheckInDate:'',
        HotelsCustomerCheckOutDate:'',
        HotelsCustomerNumberOfPeople:'',

        // Transport 
        TransportDestinationName:'',
        TransportCustomerFromDate:'',
        TransportCustomerToDate:'',
        TransportCustomerType:'',

    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APIPackageList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({PackageTourDataList:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })

    axios.get(APIHotelList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({HotelDataList:jsonResults})

        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
    axios.get(APITransportList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({TransportNamesList:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}


setToursDestinationNameInput = (code,text) =>{this.setState({ToursDestinationCode:code});this.setState({ToursDestinationName:text});}
setToursTourNameInput = (text) =>{this.setState({ToursTourName:text});}
setToursPeriodNameInput = (text) =>{this.setState({ToursPeriodName:text});}

setHotelsDestinationNameInput = (text) =>{this.setState({HotelsDestinationName:text.target.value})}
setHotelsCustomerNumberOfPeopleInput = (text) =>{this.setState({HotelsCustomerNumberOfPeople:text.target.value});}
setHotelsCustomerCheckInDateInput = (text) =>{this.setState({HotelsCustomerCheckInDate:text.target.value});}
setHotelsCustomerCheckOutDateInput = (text) =>{this.setState({HotelsCustomerCheckOutDate:text.target.value});}

setTransportDestinationNameInput = (text) =>{this.setState({TransportDestinationName:text.target.value});}
setTransportCustomerFromDateInput = (text) =>{this.setState({TransportCustomerFromDate:text.target.value});}
setTransportCustomerToDateInput = (text) =>{this.setState({TransportCustomerToDate:text.target.value});}
setTransportCustomerTypeInput = (text) =>{this.setState({TransportCustomerType:text.target.value});}

handleFilterClick = (filterName) => {
    this.setState({activeFilter:filterName});
    if (filterName === "Tours"){this.showToursSearchScreen ()}
    else if (filterName === "Hotels"){this.showHotelsSearchScreen ()}
    else if (filterName === "Transport"){this.showTransportSearchScreen ()}

};

showToursSearchFilter = () =>{
    if (this.state.ToursDestinationName.length === 0 | this.state.ToursTourName.length === 0 | this.state.ToursPeriodName.length === 0){
        this.setState({ShowRequiredError:"Error :: Tours All Selections Are Required"})
    }
    else{
        let code = this.state.ToursDestinationCode;
        let SearchDetails = { DestinationName:this.state.ToursDestinationName, TourName:this.state.ToursTourName,PeriodName:this.state.ToursPeriodName};
        let searchData = this.state.PackageTourDataList.filter(item => item.country === code);
        localStorage.setItem('SearchDetails',JSON.stringify(SearchDetails));
        localStorage.setItem('SearchData',JSON.stringify(searchData));
        this.props.navigate("/tourFilter");
    }
}
showHotelsSearchFilter = () =>{
    if (this.state.HotelsDestinationName.length === 0 | this.state.HotelsCustomerCheckInDate.length === 0 | 
        this.state.HotelsCustomerCheckOutDate.length === 0 | this.state.HotelsCustomerNumberOfPeople.length === 0){
        this.setState({ShowRequiredError:"Error :: Hotel All Selections Are Required"})
    }
    else{
        let code = this.state.HotelsDestinationName;
        let hotelsData = this.state.HotelDataList.filter(item => item.country === code);
        let hotelDetails = { 
            DestinationName:this.state.HotelsDestinationName, CheckIn:this.state.HotelsCustomerCheckInDate,
            CheckOut:this.state.HotelsCustomerCheckOutDate,People:this.state.HotelsCustomerNumberOfPeople};
        localStorage.setItem('HotelDetails',JSON.stringify(hotelDetails));
        localStorage.setItem('HotelsData',JSON.stringify(hotelsData));
        localStorage.setItem('HotelDataList',JSON.stringify(this.state.HotelDataList))
        this.props.navigate("/hotelsFilter");
    }
}

showTransportSearchFilter = () =>{
    if (this.state.TransportDestinationName.length === 0 | this.state.TransportCustomerFromDate.length === 0 | 
        this.state.TransportCustomerToDate.length === 0 | this.state.TransportCustomerType.length === 0){
        this.setState({ShowRequiredError:"Error :: Hotel All Selections Are Required"})
    }
    else{
        let transportData = this.state.TransportNamesList.filter(item => item.country === this.state.TransportDestinationName);
        let transportDetails = { 
            DestinationName:this.state.TransportDestinationName, FromDate:this.state.TransportCustomerFromDate,
            ToDate:this.state.TransportCustomerToDate,Type:this.state.TransportCustomerType};
        localStorage.setItem('TransportDetails',JSON.stringify(transportDetails));
        localStorage.setItem('TransportData',JSON.stringify(transportData));
        localStorage.setItem('TransportNamesList',JSON.stringify(this.state.TransportNamesList))
        this.props.navigate("/transportFilter");
    }
}



showToursSearchScreen = ()=>{
    this.setState({ShowToursSearchScreen:true})
    this.setState({ShowHotelsSearchScreen:false})
    this.setState({ShowTransportsSearchScreen:false})
}
showHotelsSearchScreen = ()=>{
    this.setState({ShowHotelsSearchScreen:true})
    this.setState({ShowToursSearchScreen:false})
    this.setState({ShowTransportsSearchScreen:false})
}
showTransportSearchScreen = ()=>{
    this.setState({ShowTransportsSearchScreen:true})
    this.setState({ShowToursSearchScreen:false})
    this.setState({ShowHotelsSearchScreen:false})
}

render() {
const { HotelsCustomerCheckInDate,HotelsCustomerCheckOutDate,
        
        TransportCustomerFromDate,TransportCustomerToDate,
    } =this.state;
return (
        <div className='body-container'>    
            <center>
                <div className='search-filter-headings'>
                    <div className='my-grid-container-3-columns '>
                        <div className='search-filter-grid-item grid-separator-right'>
                            <span className={`custom-button-span ${this.state.activeFilter === "Tours" ? "active" : ""}`}
                            onClick={() => this.handleFilterClick("Tours")}
                            >Tours</span>
                        </div>
                        <div className='search-filter-grid-item grid-separator-right'>
                            <span className={`custom-button-span ${this.state.activeFilter === "Hotels" ? "active" : ""}`}
                            onClick={() => this.handleFilterClick("Hotels")}
                            >Hotels</span>
                        </div>
                        <div className='search-filter-grid-item'>
                            <span className={`custom-button-span ${this.state.activeFilter === "Transport" ? "active" : ""}`}
                            onClick={() => this.handleFilterClick("Transport")}
                            >Transport</span>
                        </div>
                    </div>
                </div>

                {/* ============================ Tours Screen ================================ */}
                {this.state.ShowToursSearchScreen ?(<>
                    <div className='search-filter-selection-inputs-area'>
                        <div className='my-grid-container-4-columns'>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <Nav>
                                    <NavDropdown id="search-select-a-destination-id" 
                                        title={this.state.ToursDestinationName.length !==0 ?(<>{this.state.ToursDestinationName}</>):(<>{"Select A Destination"}</>)}>
                                        {DestinationNamesList && DestinationNamesList.map((item, index)=>(
                                            <div key={index}>
                                                <NavDropdown.Item  className='search-filter-labels' onClick={()=>this.setToursDestinationNameInput(item.code,item.name)}>
                                                    <label > {item.name}</label>
                                                </NavDropdown.Item>
                                                <NavDropdown.Divider />
                                            </div>
                                    ))}
                                    </NavDropdown>
                                </Nav> 
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <Nav>
                                    <NavDropdown id="search-select-a-tour-id" 
                                        title={this.state.ToursTourName.length !==0 ?(<>{this.state.ToursTourName}</>):(<>{"Select A Safari Tour"}</>)} >
                                        {TourNamesList && TourNamesList.map((item, index)=>(
                                            <div key={index}>
                                                <NavDropdown.Item  className='search-filter-labels' onClick={()=>this.setToursTourNameInput(item.name)}>
                                                    <label > {item.name}</label>
                                                </NavDropdown.Item>
                                                <NavDropdown.Divider />
                                            </div>
                                    ))}
                                    </NavDropdown>
                                </Nav> 
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <Nav>
                                    <NavDropdown id="search-select-a-days-id" 
                                        title={this.state.ToursPeriodName.length !==0 ?(<>{this.state.ToursPeriodName}</>):(<>{"Select A Period"}</>)} >
                                        {TourDaysList && TourDaysList.map((item, index)=>(
                                            <div key={index}>
                                                <NavDropdown.Item  className='search-filter-labels' onClick={()=>this.setToursPeriodNameInput(item.name)}>
                                                    <label > {item.name}</label>
                                                </NavDropdown.Item>
                                                <NavDropdown.Divider />
                                            </div>
                                    ))}
                                    </NavDropdown>
                                </Nav> 
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item'>
                                <button type="button" onClick={()=>this.showToursSearchFilter()} className="custom-button">Search </button>
                            </div>
                        </div>
                    </div>
                </>):(<></>)}

                {/* ============================ Hotels Screen ================================ */}
                {this.state.ShowHotelsSearchScreen ?(<>
                    <div className='search-filter-selection-inputs-area'>
                        <div className='my-grid-container-5-columns'>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <p className="search-label-text">Destination </p>
                                <select className="form-control search-form-inputs" 
                                onChange={text => this.setHotelsDestinationNameInput(text)}>
                                    <option >Your Destination</option>
                                    <option></option>
                                    {DestinationNamesList && DestinationNamesList.map((item,index)=>(
                                        <option key={index} value={item.code} > {item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <p className="search-label-text">Check In</p>
                                <input type='date' className="form-control search-form-inputs"
                                    value={HotelsCustomerCheckInDate}
                                    onChange={text => this.setHotelsCustomerCheckInDateInput(text)}
                                />
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <p className="search-label-text">Check Out</p>
                                <input type='date' className="form-control search-form-inputs"
                                    value={HotelsCustomerCheckOutDate}
                                    onChange={text => this.setHotelsCustomerCheckOutDateInput(text)}
                                />
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <p className="search-label-text">Range Class</p>
                                <select className="form-control search-form-inputs" 
                                    onChange={text => this.setHotelsCustomerNumberOfPeopleInput(text)}>
                                    <option >Select A Class</option>
                                    <option></option>
                                    {AccommodationRangesList && AccommodationRangesList.map((item,index)=>(
                                        <option key={index} value={item.name} > {item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item'>
                                <button type="button" onClick={()=>this.showHotelsSearchFilter()} className="custom-button-2">Search </button>
                            </div>
                        </div>
                    </div>
                </>):(<></>)}

                {/* ============================ Transport Screen ================================ */}
                {this.state.ShowTransportsSearchScreen ?(<>
                    <div className='search-filter-selection-inputs-area'>
                        <div className='my-grid-container-5-columns'>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <p className="search-label-text">Destination </p>
                                <select className="form-control search-form-inputs" 
                                onChange={text => this.setTransportDestinationNameInput(text)}>
                                    <option >Your Destination</option>
                                    <option>Select</option>
                                    {DestinationNamesList && DestinationNamesList.map((item,index)=>(
                                        <option key={index} value={item.code} > {item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <p className="search-label-text">From</p>
                                <input type='date' className="form-control search-form-inputs"
                                    value={TransportCustomerFromDate}
                                    onChange={text => this.setTransportCustomerFromDateInput(text)}
                                />
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <p className="search-label-text">To</p>
                                <input type='date' className="form-control search-form-inputs"
                                    value={TransportCustomerToDate}
                                    onChange={text => this.setTransportCustomerToDateInput(text)}
                                />
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item selection-grid-separator-right'>
                                <p className="search-label-text">Transport Type</p>
                                <select className="form-control search-form-inputs" 
                                    onChange={text => this.setTransportCustomerTypeInput (text)}>
                                    <option >Select A Type</option>
                                    <option></option>
                                    {TransportTypeList && TransportTypeList.map((item,index)=>(
                                        <option key={index} value={item.name} > {item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='search-filter-selection-inputs-area-grid-item'>
                                <button type="button" onClick={()=>this.showTransportSearchFilter()} className="custom-button-2">Search </button>
                            </div>
                        </div>
                    </div>
                </>):(<></>)}
                <p className='text-danger'>{this.state.ShowRequiredError}</p>
            </center>


            
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}
export default withNavigation(SearchFilter);